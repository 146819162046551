import * as React from 'react';
import {useState} from "react";
import {Alert, Button, Card, Radio, Input, Row, Typography, Space, Flex} from "antd";
import styled from "styled-components";
import {PrimaryButton} from "./Buttons";
import TextArea from "antd/lib/input/TextArea";
import { posthogCapture, posthog } from "../../shared/posthogCapture";
import GalleryModal from "./GalleryModal";
const CREATE_JIRA_TICKET_IN_DOCS_BOARD_ENDPOINT_URL = "/.netlify/functions/createJiraTicketInDocsBoard";
const FEEDBACK_POSTHOG_SURVEY_ID = "018dd725-c595-0000-00c6-6eec1b197fd0";

const {Title, Paragraph} = Typography;

const StyledRow = styled(Row)`
    .ant-typography:not(a),
    body,
    li
    {
        color: ${props => props.theme.colors.darkGray};
    }
`;

const StyledCard = styled(Card)`
    margin: 2rem auto;
    width: 100%;
    max-width: 30rem;
    justify-content: center;
    
    .ant-card-body {
        padding: 0.75rem;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
    }
`

const StyledTitle = styled(Title)`
    display: inline;
    color: ${props => props.theme.colors.darkGray};
    font-family: "Source Sans Pro", sans-serif;
    margin: 0;

    &&& {
        margin-bottom: 0;
        font-size: ${props => props.theme.fontSize.lg};
        font-weight: ${props => props.theme.fontWeight.semibold};
    }
`;

const StyledButton = styled(Button)`
    min-width: 4.5rem;
    padding: 0.25rem;
    border-color: ${props => props.theme.colors.orange};
`

const StyledAlert = styled(Alert)`
    margin: 2rem auto;
    color: ${props => props.theme.colors.darkGray};
    font-family: "Source Sans Pro", sans-serif;
    font-size: ${props => props.theme.fontSize.lg};
    background: ${props => props.theme.colors.lightestGray};
    border-color: ${props => props.theme.colors.orange};
    color: ${props => props.theme.colors.darkGray};
`

const ModalContentWrapper = styled.section`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: stretch;
    margin: auto;
    padding: 1.5rem;
    max-height: 70vh;
    overflow-y: auto;

    .ant-input, .ant-radio-wrapper, .ant-radio-group, .ant-typography {
        font-size: ${props => props.theme.fontSize.lg};
        color: ${props => props.theme.colors.darkGray};
        font-weight: ${props => props.theme.fontWeight.regular};
    }
`
const THANK_YOU_MESSAGE = "Thank you for helping us improve our documentation!"

const ErrorMessage = styled.p`
    color: ${props => props.theme.colors.darkRed};
    font-size: ${props => props.theme.fontSize.md};
    margin: 0;  
`

type FeedbackModalProps = {
    location: Location
}

const FeedbackModal = ({location}: FeedbackModalProps) => {
    const [feedbackSent, setFeedbackSent] = useState(false)
    const [isOpen, setIsOpen] = useState(false);
    const [error, setError] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        selectedValue: '',
        description: ''
    });
    const pathname = location.href;

    const handleChange = (e) => {
        const { name, value } = e.target
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }))
    };

    const handleFeedbackReaction = (eventName) => {
        if(feedbackSent) return;
        setFeedbackSent(true)
        posthogCapture(eventName, { doc_url: pathname })
    };

    const dismissFeedbackModal = () => {
        setIsOpen(false)
        posthog("survey dismissed", {
            $survey_id: FEEDBACK_POSTHOG_SURVEY_ID
        });
    }

    const handleNegativeFeedbackReaction = () => {
        setIsOpen(true)
        handleFeedbackReaction('feedback_no')
    }

    const sendReview = async (e) => {
        e.preventDefault()
        if (formData.description) {
            setError(false)
            posthog("survey sent", {
                $survey_id: FEEDBACK_POSTHOG_SURVEY_ID,
                $survey_response: formData.name,
                $survey_response_1: formData.email,
                $survey_response_2: formData.description,
                $survey_response_3: pathname,
                $survey_response_4: formData.selectedValue.replaceAll('-', ' ')
            })
            try {
                const response = await fetch(CREATE_JIRA_TICKET_IN_DOCS_BOARD_ENDPOINT_URL, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ ...formData, pathname })
                });
                if (response.ok) {
                    setIsOpen(false)
                } else {
                    setError(true)
                }
            } catch (error) {
                setError(true)
            }
        }
    }

    return (
        <StyledRow>
            { !feedbackSent &&
                <StyledCard>
                    <StyledTitle level={4}>Was this topic helpful?</StyledTitle>
                        <StyledButton disabled={feedbackSent}
                                onClick={() => handleFeedbackReaction('feedback_yes')}>Yes
                        </StyledButton>
                        <StyledButton disabled={feedbackSent}
                                onClick={handleNegativeFeedbackReaction}>No
                        </StyledButton>
                </StyledCard>
            }
            <GalleryModal
                isOpen={isOpen} onCancel={dismissFeedbackModal}
                footerStyling={{padding: "0 1.5rem 1rem", marginTop: 0}}
                titleContent="What is the problem?"
                footer={
                    <PrimaryButton
                        onClick={sendReview}
                        style={{ minWidth: '100%', borderRadius: '6px' }}
                        disabled={!formData.description}
                    >Submit</PrimaryButton>
                }
            >
                <ModalContentWrapper>
                    <Flex gap="large">
                        <Input placeholder="Name (optional)"
                               name="name"
                               value={formData.name}
                               onChange={handleChange}/>
                        <Input placeholder="Email (optional)"
                               name="email"
                               value={formData.email}
                               onChange={handleChange}/>
                    </Flex>
                    <Radio.Group name="selectedValue" onChange={handleChange} value={formData.selectedValue}>
                        <Space direction="vertical">
                            <Radio value="language-typo">Language Typo</Radio>
                            <Radio value="inaccurate">Inaccurate</Radio>
                            <Radio value="code-sample-errors">Code sample errors</Radio>
                            <Radio value="need-gx-support">I need GX support</Radio>
                        </Space>
                    </Radio.Group>
                    {formData.selectedValue === 'need-gx-support' &&
                        <Paragraph style={{marginBottom: '0'}}>
                            Visit our <a href="https://docs.greatexpectations.io/docs/resources/get_support">Get
                            Support
                            page</a>.
                        </Paragraph>}

                    {['language-typo', 'inaccurate', 'code-sample-errors'].includes(formData.selectedValue) &&
                        <TextArea
                            value={formData.description}
                            name="description"
                            onChange={handleChange}
                            placeholder={formData.selectedValue === 'language-typo' ? "Describe the typo that you've found." : "Try to be specific and detailed."}
                            autoSize={{ minRows: 3, maxRows: 5 }}
                        />
                    }

                    {error && <ErrorMessage>An error occurred, please try again later.</ErrorMessage>}

                </ModalContentWrapper>
            </GalleryModal>
            { feedbackSent && !isOpen &&
                <StyledAlert message={THANK_YOU_MESSAGE}/>
            }
        </StyledRow>
    );
}

export {FeedbackModal, THANK_YOU_MESSAGE};
